import { WittyMessage } from '@agilelab/plugin-wb-platform';

export const SAVE_QUESTION: WittyMessage = {
  contentMessage: `Do you want to save the current prototype?
    \na. Yes
    \nb. No`,
  sender: 'witty',
};

export const METADATA_GENERATION_PROMPT: WittyMessage = {
  contentMessage: `Help me create a new prototype`,
  sender: 'you',
};

export const NOT_SATISFIED: WittyMessage = {
  contentMessage: "I'm not satisfied yet",
  sender: 'you',
};
