import { useUserProfile } from '@agilelab/plugin-wb-platform';
import { Avatar } from '@material-ui/core';
import React from 'react';

export const UserAvatar: React.FC = () => {
  const { profile } = useUserProfile();

  return (
    <Avatar
      alt="profile image"
      src={profile.picture}
      style={{ width: '24px', height: '24px' }}
    />
  );
};
