import {
  BusinessConceptsPayload,
  BusinessConceptsResponse,
  DataSourcesPayload,
  DataSourcesResponse,
  GetBusinessConceptsResponse,
  GetDataSourcesResponse,
  GetKeyResultsResponse,
  KeyResultsPayload,
  KeyResultsResponse,
  MetadataPayload,
  MetadataResponse,
  PrototypeApi,
  RequestOptions,
} from './PrototypeApi';
import fetch from 'cross-fetch';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';
import { BUSINESS_CONCEPTS, DATA_SOURCES, KEY_RESULTS } from './constants';
import { Prototype, PrototypeWithId } from './types';
import { getMockResponse } from './PrototypeClient.mock';
import { z } from 'zod';

export const PrototypeZod = z.object({
  name: z.string().nullable(),
  domain: z.string().nullable(),
  type: z.string().nullable(),
  description: z.string().nullable(),
  purpose: z.string().nullable(),
  businessConcepts: z.array(z.any()).optional().nullable(),
  keyResults: z.array(z.any()).optional().nullable(),
  dataSources: z.array(z.any()).optional().nullable(),
});

export class PrototypeClient implements PrototypeApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch };
  }
  async getPrototypeBy(
    id: number,
    options?: RequestOptions,
  ): Promise<PrototypeWithId | undefined> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}/prototype/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
    });

    await handleFailedResponse(response);

    return await response.json();
  }
  async getDataSources(
    options?: RequestOptions,
  ): Promise<GetDataSourcesResponse> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}/data-sources`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
    });

    await handleFailedResponse(response);

    return await response.json();
  }
  async getBusinessConcepts(
    options?: RequestOptions,
  ): Promise<GetBusinessConceptsResponse> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}/business-concepts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
    });

    await handleFailedResponse(response);

    return await response.json();
  }
  async getKeyResults(
    options?: RequestOptions,
  ): Promise<GetKeyResultsResponse> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}/key-results`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
    });

    await handleFailedResponse(response);

    return await response.json();
  }

  async generateMetadata(
    payload: MetadataPayload,
    options?: RequestOptions,
  ): Promise<MetadataResponse> {
    const baseUrl = await this.getBaseUrl();
    const body = {
      prototype: PrototypeZod.parse(payload.prototype),
      message: payload.message,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/generate/metadata/${payload.options.user}/${payload.options.session}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
        body: JSON.stringify(body),
      },
    );
    await handleFailedResponse(response);

    return await response.json();
  }
  async generateKeyResults(
    payload: KeyResultsPayload,
    options?: RequestOptions,
  ): Promise<KeyResultsResponse> {
    const baseUrl = await this.getBaseUrl();
    const body = { prototype: PrototypeZod.parse(payload.prototype) };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/generate/key-results/${payload.options.user}/${payload.options.session}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
        body: JSON.stringify(body),
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }
  async generateDataSources(
    payload: DataSourcesPayload,
    options?: RequestOptions,
  ): Promise<DataSourcesResponse> {
    const baseUrl = await this.getBaseUrl();
    const body = { prototype: PrototypeZod.parse(payload.prototype) };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/generate/data-sources/${payload.options.user}/${payload.options.session}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
        body: JSON.stringify(body),
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }
  async generateBusinessConcepts(
    payload: BusinessConceptsPayload,
    options?: RequestOptions,
  ): Promise<BusinessConceptsResponse> {
    const baseUrl = await this.getBaseUrl();
    const body = { prototype: PrototypeZod.parse(payload.prototype) };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/generate/business-concepts/${payload.options.user}/${payload.options.session}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
        body: JSON.stringify(body),
      },
    );

    await handleFailedResponse(response);

    return await response.json();
  }
  async editPrototype(
    prototype: PrototypeWithId,
    options?: RequestOptions,
  ): Promise<void> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(
      `${baseUrl}/prototype/${prototype.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
        body: JSON.stringify(PrototypeZod.parse(prototype)),
      },
    );

    await handleFailedResponse(response);
  }

  async insertPrototype(
    prototype: Prototype,
    options?: RequestOptions,
  ): Promise<void> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}/prototype`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: JSON.stringify(prototype),
    });

    await handleFailedResponse(response);
  }
  async deletePrototypes(
    request: number[],
    options?: RequestOptions,
  ): Promise<void> {
    const baseUrl = await this.getBaseUrl();
    const body = request;
    const response = await this.fetchApi.fetch(`${baseUrl}/prototypes`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: JSON.stringify(body),
    });

    await handleFailedResponse(response);
  }
  async listPrototypes(options?: RequestOptions): Promise<PrototypeWithId[]> {
    const baseUrl = await this.getBaseUrl();
    const response = await this.fetchApi.fetch(`${baseUrl}/prototypes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
    });

    await handleFailedResponse(response);

    return await response.json();
  }

  private async getBaseUrl() {
    return this.discoveryApi.getBaseUrl('prototype');
  }
}

export class PrototypeMockClient implements PrototypeApi {
  async editPrototype(
    _prototype: PrototypeWithId,
    _options?: RequestOptions | undefined,
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }
  async insertPrototype(
    _prototype: Prototype,
    _options?: RequestOptions | undefined,
  ): Promise<void> {
    throw new Error('Method not implemented.');
  }
  async getPrototypeBy(_id: number): Promise<PrototypeWithId | undefined> {
    throw new Error('Method not implemented.');
  }
  async getDataSources(
    _options?: RequestOptions,
  ): Promise<GetDataSourcesResponse> {
    return { items: DATA_SOURCES };
  }
  async getBusinessConcepts(
    _options?: RequestOptions,
  ): Promise<GetBusinessConceptsResponse> {
    return { items: BUSINESS_CONCEPTS };
  }
  async getKeyResults(
    _options?: RequestOptions,
  ): Promise<GetKeyResultsResponse> {
    return { items: KEY_RESULTS };
  }
  async generateMetadata(
    payload: MetadataPayload,
    _options?: RequestOptions,
  ): Promise<MetadataResponse> {
    return getMockResponse(payload);
  }
  async generateKeyResults(
    payload: KeyResultsPayload,
    _options?: RequestOptions,
  ): Promise<KeyResultsResponse> {
    const proto = payload.prototype;
    return { prototype: { ...proto, keyResults: KEY_RESULTS.slice(1, 5) } };
  }
  async generateDataSources(
    payload: DataSourcesPayload,
    _options?: RequestOptions,
  ): Promise<DataSourcesResponse> {
    const proto = payload.prototype;
    return { prototype: { ...proto, dataSources: DATA_SOURCES.slice(1, 5) } };
  }
  async generateBusinessConcepts(
    payload: BusinessConceptsPayload,
    _options?: RequestOptions,
  ): Promise<BusinessConceptsResponse> {
    const proto = payload.prototype;
    return {
      prototype: { ...proto, businessConcepts: BUSINESS_CONCEPTS.slice(1, 5) },
    };
  }
  async deletePrototypes(
    _request: number[],
    _options?: RequestOptions,
  ): Promise<void> {
    throw new Error('');
  }
  async listPrototypes(_options?: RequestOptions): Promise<PrototypeWithId[]> {
    throw new Error('');
  }
}
