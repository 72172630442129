import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import {
  BrandIcon,
  ContentCopyIcon,
  DownloadIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from './icons';

export const Feedbacks: React.FC = () => {
  const theme = useTheme();
  const style: React.CSSProperties = { color: theme.palette.secondary.main };
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ gap: '8px' }}
      paddingLeft="40px"
      marginBottom="10px"
    >
      <BrandIcon style={style} />
      <ContentCopyIcon style={style} />
      <DownloadIcon style={style} />
      <ThumbDownIcon style={style} />
      <ThumbUpIcon style={style} />
    </Box>
  );
};
