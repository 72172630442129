import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { MicIcon, SendIcon, AttachFileIcon } from './icons';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.bkg.secondary,
    border: `1px solid ${theme.palette.softened.secondary}`,
    padding: '16px',
    borderRadius: '16px',
    marginBottom: '24px',
    pointerEvents: 'none',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

export const TextBox: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const style = { color: theme.palette.secondary.light };

  return (
    <Box className={classes.container}>
      <Box className={classes.actions}>
        <MicIcon fontSize="small" style={style} />
        <AttachFileIcon fontSize="small" style={style} />
        <Typography variant="body2" style={style}>
          Type Message
        </Typography>
      </Box>
      <SendIcon fontSize="small" style={style} />
    </Box>
  );
};
